import React from 'react';
import styled from 'styled-components/macro';

import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';

import colors from '../../assets/colors';
import fontSize from '../../assets/fontSize';

const AccessPointImage = '../../assets/img/project/access-project-image.png';

const Container = styled.div`
  padding: 2rem 0;
  margin-top: 3rem;
`;
const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1.5rem;
`;
const StyledCard = styled(Card)`
  background-color: ${colors.blue};
  border-radius: 20px;
`;
const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  color: ${colors.white};

  @media screen and (min-width: 800px) {
    flex-direction: row;
    align-items: center;
    gap: 3rem;
  }
`;
const ImageWrapper = styled.div`
  max-width: 450px;
  width: 100%;
  border-radius: 20px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);

  @media screen and (min-width: 800px) {
    width: 40%;
  }
`;
const TextContent = styled.div`
  flex: 1;
`;
const Title = styled.h3`
  font-size: ${fontSize.twoRem};
  font-weight: 700;
  margin: 0;
  padding: 1rem 0;
`;
const SubtitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
const Subtitle = styled.h4`
  font-size: ${fontSize.xLarge};
  font-weight: 500;
`;
const Badge = styled.span`
  background-color: ${colors.redLight};
  color: ${colors.white};
  padding: 0.15rem 0.25rem;
  border-radius: 4px;
  font-size: ${fontSize.medium};
  position: relative;
  top: -0.25rem;
`;
const BodyText = styled.p`
  font-size: ${fontSize.large};
`;
const ButtonWrapper = styled.div`
  padding: 1rem 0;
`;
const AccessButton = styled(Button)`
  border-color: ${colors.white};
  color: ${colors.white};

  @media screen and (min-width: 800px) {
    font-size: ${fontSize.xLarge};
  }
`;

const ProjectAccessPoint = () => {
  return (
    <Container>
      <Content>
        <StyledCard elevation={5}>
          <CardContent>
            <ImageWrapper>
              <StaticImage src={AccessPointImage} alt="Access Project" />
            </ImageWrapper>
            <TextContent>
              <SubtitleWrapper>
                <Subtitle>GoGet Projects</Subtitle>
                <Badge>New</Badge>
              </SubtitleWrapper>
              <Title>End-to-end Staffing Solution</Title>
              <BodyText>
                Our agile team powered by advanced in-house recruitment
                technology, stands ready to deliver seamless end-to-end
                solutions for your hiring needs
              </BodyText>
              <ButtonWrapper>
                <Link to="/business/projects">
                  <AccessButton size="large" variant="outlined">
                    Check out GoGet Projects
                  </AccessButton>
                </Link>
              </ButtonWrapper>
            </TextContent>
          </CardContent>
        </StyledCard>
      </Content>
    </Container>
  );
};

export default ProjectAccessPoint;
