import React from 'react';
import styled from 'styled-components/macro';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

import TitleHeader from '../../components/TitleHeaderText';

import Button from '@material-ui/core/Button';

import ArrowForward from '@material-ui/icons/ArrowForward';

import fontSize from '../../assets/fontSize';

const Container = styled.div`
  width: 100%;
  min-height: 200px;
  max-width: 1300px;
  margin: auto;
`;
const Row = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${({ mobile, reverse }) =>
    mobile ? 'column' : reverse ? 'row-reverse' : 'row'};
  gap: 0.5rem;
`;
const SectionTitle = styled.h3`
  font-size: ${fontSize.twoRem};
  font-weight: bold;
  margin: 0;
`;
const SectionSubtitle = styled.p`
  font-size: ${fontSize.twoRem};
  font-weight: 500;
  max-width: 400px;
  margin: 0;
`;
const SectionDescription = styled.p`
  font-size: ${fontSize.xLarge};
  white-space: pre-wrap;
`;
const ContentSection = styled.div`
  width: ${({ mobile }) => (mobile ? '100%' : '50%')};
  padding: 0.5rem;
`;

const HRSolutions = ({ mobile, sections }) => {
  return (
    <Container>
      <TitleHeader text="One-Stop HR Solution" />
      {sections?.map((section, index) => {
        const { heroImage, title, subtitle, description, ctaText, ctaPath } =
          section;
        const image = getImage(heroImage);

        return (
          <Row
            key={section.originalId}
            mobile={mobile}
            reverse={index % 2 === 0}
          >
            <GatsbyImage image={image} alt={heroImage?.alt} />
            <ContentSection mobile={mobile}>
              {title && <SectionTitle>{title}</SectionTitle>}
              {subtitle && <SectionSubtitle>{subtitle}</SectionSubtitle>}
              {description && (
                <SectionDescription>{description}</SectionDescription>
              )}
              {ctaText && ctaPath && (
                <Button
                  component={Link}
                  to={ctaPath}
                  color="primary"
                  variant="outlined"
                  size="large"
                  endIcon={<ArrowForward />}
                  target={ctaPath.includes('https://') ? '_blank' : '_self'}
                  rel="noreferrer noopener"
                >
                  {ctaText}
                </Button>
              )}
            </ContentSection>
          </Row>
        );
      })}
    </Container>
  );
};

export default HRSolutions;
