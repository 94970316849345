import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import TitleHeaderText from '../../components/TitleHeaderText';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const MainDiv = styled.div`
  width: 100%;
  margin: auto;
  @media (max-width: 480px) {
    width: 100%;
  }
  .slick-next {
    display: none !important;
  }
`;

function BusinessLogoSlider({ logos }) {
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    window.addEventListener('resize', updateWindowDimensions);
    return updateWindowDimensions();
  });

  const updateWindowDimensions = () => {
    if (window.innerWidth <= 750 && !mobile) {
      setMobile(true);
    } else if (window.innerWidth > 750 && mobile) {
      setMobile(false);
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: !mobile ? 8 : 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    draggable: false,
    cssEase: 'linear'
  };

  return (
    <MainDiv>
      <TitleHeaderText text="We've helped 10,000+ businesses" />
      <Slider {...settings}>
        {logos?.map((logo, index) => {
          const image = getImage(logo);
          return (
            <div key={index}>
              <GatsbyImage image={image} alt={logo.alt} />
            </div>
          );
        })}
      </Slider>
    </MainDiv>
  );
}

export default BusinessLogoSlider;
